import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div style={{ marginTop: '2rem'}}>
      <h1>Page introuvable</h1>
      <p>Cette page n'existe pas ... dommage !</p>
    </div>
  </Layout>
)

export default NotFoundPage
